import React from "react";
import { Link } from "react-router-dom";
import styled, {keyframes} from "styled-components";
import { ReactComponent as WheelIcon } from './assets/wheel.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33px;
  background: #ffffff;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  color: black;
  font-family: 'Exo', sans-serif;
  text-decoration: none;
  &:hover svg {
    animation: ${rotate} 2s linear infinite;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const StyledLink = styled(Link)`
  margin: 0 10px;
  color: black;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Exo', sans-serif;
  &:hover {
    color: #ccc;
  }
`;

const Header: React.FC = () => (
  <HeaderContainer>
    <LogoLink to="/">
      <WheelIcon width={64} height={64}/>
      Isaac <br/>Gonzalez
    </LogoLink>
    <LinksContainer>
      <StyledLink to="/routes/kiel">KIEL!</StyledLink>
      <StyledLink to="/routes/peru">PERÚ!</StyledLink>
    </LinksContainer>
  </HeaderContainer>
);

export default Header;
