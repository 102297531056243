import * as React from "react";
import styled from "styled-components";
import EmbeddedMap from "../molecules/EmbeddedMap";
import Banner from "../atoms/Banner";

const Background = styled.div`
  background-image: url("/night_ride_24h.JPG");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HomeText = styled.h1`
  color: white;
  font-size: 4rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
`;

const Home: React.FC = () => {
  return (
    <>
      <Background>
        <HomeText>IF YOU DON'T, SOMEBODY WILL</HomeText>
      </Background>
      <Banner title="Where is he now?" />
      <EmbeddedMap />
    </>
  );
};

export default Home;
