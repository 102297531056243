import * as React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-grid-system";
import styled from "styled-components";
import { HorizontalSpacer } from "../atoms/Spacers";

const StravaEmbedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RoutesKiel: React.FC = () => {
  React.useEffect(() => {
    document.title = "Let's ride to Kiel";
  }, []);

  return (
    <>
      <Helmet>
        <title>Let's ride to Kiel</title>
        <script src="https://strava-embeds.com/embed.js"></script>
      </Helmet>
      <Container style={{maxWidth: "1200px"}} >
        <div>
          <h1>We are riding to Kiel to buy beer!</h1>
        </div>
        <StravaEmbedContainer>
          <Row justify="between" style={{width: "100%"}}>
            <Col>
              <HorizontalSpacer spacing={1} />
              <h2>Day 1</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3232773915770914948"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-surface-type="true"
                data-map-hash="5.31/56.92/12.503"
                data-from-embed="true"
              ></div>
              <HorizontalSpacer spacing={2} />
              <h2>Day 2</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3232774667333337600"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-surface-type="true"
                data-map-hash="9.57/56.0822/12.6493"
                data-from-embed="true"
              ></div>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3232779605632832064"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-surface-type="true"
                data-map-hash="5.58/55.347/12.171"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={2} />
              <h2>Day 3</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3232779898759159940"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3232780232231050372"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
            </Col>
          </Row>
        </StravaEmbedContainer>
      </Container>
    </>
  );
};

export default RoutesKiel;
