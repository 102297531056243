import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/templates/Home";
import RoutesKiel from "./components/templates/RoutesKiel";
import RoutesPeru from "./components/templates/RoutesPeru";
import Header from "./Header";

function App() {
  React.useEffect(() => {
    document.title = "Biking is easy!";
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/routes/kiel" element={<RoutesKiel />} />
          <Route path="/routes/peru" element={<RoutesPeru />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
