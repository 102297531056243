import * as React from "react";
import styled from "styled-components";

const EmbeddedMapContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 1080px;
  background-color: #000000;
  padding: 100px;
  padding-top: 0;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;

const EmbeddedMap: React.FC = () => {
  return (
    <EmbeddedMapContainer>
      <IframeContainer>
        <iframe
          title="Garmin Map share"
          width="100%"
          height="100%"
          style={{ border: 0, margin: 0, padding: 0, minHeight: "1080px" }}
          src="https://share.garmin.com/naranjas"
        ></iframe>
      </IframeContainer>
    </EmbeddedMapContainer>
  );
};

export default EmbeddedMap;
