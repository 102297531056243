
export const Breakpoints = {
  XS: 540,
  SM: 740,
  MD: 960,
  LG: 1340,
  XL: 1400,
  XXL: 1400,
} as const

export enum MediaQuery {
  XS = `@media (max-width: 540px)`,
  SM = `@media (max-width: 740px)`,
  MD = `@media (max-width: 960px)`,
  LG = `@media (max-width: 1340px)`,
  XL = `@media (min-width: 1400px)`,
  XXL = `@media (min-width: 1400px)`,
}
