import * as React from 'react';
import styled from 'styled-components';

interface BannerProps {
  title: string;
}

const BannerContainer = styled.div`
  background-color: #000000;
  padding: 1rem;
  text-align: center;
`;

const BannerTitle = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
`;

const Banner: React.FC<BannerProps> = ({ title }) => {
  return (
    <BannerContainer>
      <BannerTitle>{title}</BannerTitle>
    </BannerContainer>
  );
}

export default Banner;
