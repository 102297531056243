import * as React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-grid-system";
import styled from "styled-components";
import { HorizontalSpacer } from "../atoms/Spacers";

const StravaEmbedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RoutesPeru: React.FC = () => {
  React.useEffect(() => {
    document.title = "Let's climb mountains in Perú";
  }, []);

  return (
    <>
      <Helmet>
        <title>Let's climb mountains in Perú</title>
        <script src="https://strava-embeds.com/embed.js"></script>
      </Helmet>
      <Container style={{ maxWidth: "1200px" }}>
        <div>
          <h1>Let's climb mountains in Perú</h1>
        </div>
        <StravaEmbedContainer>
          <Row justify="between" style={{ width: "100%" }}>
            <Col>
              <HorizontalSpacer spacing={1} />
              <h2>Day 1</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3252250153144151680"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-map-hash="8.8/-11.9096/-76.6651"
                data-from-embed="true"
              ></div>
              <HorizontalSpacer spacing={2} />
              <h2>Day 2</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3252253163938629676"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 3</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3252256275502211116"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 4</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3252257274513024044"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-surface-type="true"
                data-map-hash="8.26/-12.657/-75.241"
                data-from-embed="true"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 5</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3252258984722158208"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-map-hash="8.27/-12.959/-74.992"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 6</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253364310785099142"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 7</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253366039668357738"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-map-hash="10.22/-13.551/-74.491"
                data-from-embed="true"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 8</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253368375734582890"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 9</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253370869960151658"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 10</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253375737979752852"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 11</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253379420444346758"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 12</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253380952270245482"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 13</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253381208095603092"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 14</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253381747789911430"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 15</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253382171326370196"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 16</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253382583462419050"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 17</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253383485422780820"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
              <HorizontalSpacer spacing={4} />
              <h2>Day 18</h2>
              <HorizontalSpacer spacing={1} />
              <div
                className="strava-embed-placeholder"
                data-embed-type="route"
                data-embed-id="3253383977745313158"
                data-units="metric"
                data-full-width="true"
                data-style="standard"
                data-terrain="2d"
                data-surface-type="true"
                data-from-embed="false"
              ></div>
            </Col>
          </Row>
        </StravaEmbedContainer>
      </Container>
    </>
  );
};

export default RoutesPeru;
