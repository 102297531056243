import { createGlobalStyle } from "styled-components"

import Abel from "./Abel-Regular.ttf"
import Exo from "./Exo-VariableFont_wght.ttf"

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: "Abel";
        src: local("Abel"), url(${Abel}) format("truetype");
    }
    @font-face {
        font-family: "Exo";
        src: local("Exo"), url(${Exo}) format("truetype");
    }
`

export default FontStyles
